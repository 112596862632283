<template>
  <div class="course-detail">
    <van-nav-bar class="course-detail-title" fixed left-arrow @click-left="onBack" style="background: none;">
<!--      <template #right @click-right="userKefu">-->
<!--        <van-icon name="share-o" />-->
<!--      </template>-->
    </van-nav-bar>

    <div class="banner-course-detail">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#1989fa" @change="onChange">
        <van-swipe-item v-for="item in info.photopath" :key="item.id">
          <img :src="item" width="100%" style="display: block;" alt />
        </van-swipe-item>
        <div class="custom-indicator" slot="indicator">
          {{ current + 1 }}/{{info.photopath.length}}
        </div>
      </van-swipe>
    </div>
    <div class="course-title">
      <!-- <van-tag type="warning">直播+录播</van-tag> -->
      {{info.title}}
      <div class="course-price">￥{{info.scribing_price}}<span v-if="info.market_value>info.scribing_price">￥{{info.market_value}}</span>
      <span v-else></span>
      </div>
    </div>
    <van-cell center title="课程服务" class="course-service" @click="serviceShow = true">
      <template #label>
<!--        <van-tag plain color="#999999" v-for="(item,index) in info.service">{{item.title}}</van-tag>-->
      </template>
      <template #right-icon>
        <van-icon name="weapp-nav" class="weapp-nav-icon" />
      </template>
    </van-cell>
    <van-tabs v-model:active="active1" color="#1989fa" swipeable sticky animated class="shop_detail">
      <van-tab title="目录">
        <div class="courseList" v-for="(item,index) in courseList">
          <div class="courseListTitle">
            <van-tag round type="primary" size="medium">{{item.cate_name}}</van-tag>
          </div>
          <van-cell center :title="item2.title" is-link v-for="(item2,index2) in item.data">
            <template #icon>
              <van-icon name="circle" size="2rem" style="margin:0 0.6rem" />
            </template>
            <template #label>
              <span v-if="item2.courseware>0">共{{item2.courseware}}节课，{{item2.video_duration}}小时</span>
              <span v-else>课程暂未更新</span>
            </template>
          </van-cell>
        </div>
      </van-tab>
<!--      <van-tab title="评价">-->
<!--        <div class="evaluation">-->
<!--          <van-cell center title="课程评价" value="查看更多" is-link class="title-1" @click="handelCommodityEvaluation" />-->
<!--          <van-cell title="188****4233">-->
<!--            <template #icon>-->
<!--              <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />-->
<!--            </template>-->
<!--            <template #label>-->
<!--              <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>-->
<!--              <div class="evaluationContent">问题的内容在这里显示，显示在这里</div>-->
<!--            </template>-->
<!--          </van-cell>-->
<!--          <van-cell title="188****4233">-->
<!--            <template #icon>-->
<!--              <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />-->
<!--            </template>-->
<!--            <template #label>-->
<!--              <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>-->
<!--              <div class="evaluationContent">问题的内容在这里显示，显示在这里</div>-->
<!--            </template>-->
<!--          </van-cell>-->
<!--          <van-cell title="188****4233">-->
<!--            <template #icon>-->
<!--              <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />-->
<!--            </template>-->
<!--            <template #label>-->
<!--              <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>-->
<!--              <div class="evaluationContent">问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里</div>-->
<!--            </template>-->
<!--          </van-cell>-->
<!--        </div>-->
<!--      </van-tab>-->
      <van-tab title="详情">
<!--        <van-image width="100%" height="100%" fit="contain" position="center"-->
<!--          src="https://cdnoss.ksyt.com.cn/weixin/img21.png" />-->
        <div v-html="info.content" class="contentBox">

        </div>
      </van-tab>
      <van-tab title="推荐">
        <div v-if="info.recommend.length > 0">
          <van-cell center title="推荐课程" value="查看更多" is-link class="title-2" />
          <div class="shoplist1" v-for="(item, index) in info.recommend" :key="index">
            <van-card class="shop-card" :desc="item.commodity_content" :title="item.title" :thumb="item.photopath">
              <template #tags>
                <div class="tags">
                  <van-tag plain round type="warning" v-for="(item2,index2) in item.label">{{item2}}</van-tag>
                </div>
              </template>
              <template #bottom>
                <span class="price">￥{{ item.scribing_price }}</span>
                <van-button round type="danger" size="mini" class="audition" @click="handleClickCourseDetail(item.id)">试听报名</van-button>
              </template>
            </van-card>
          </div>
        </div>
        <div v-else style="background-color: #fff;padding-bottom: 4rem;">
          <van-empty
                  description="暂无推荐课程"
                  image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                  image-size="24rem">
<!--            <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus">选课中心</van-button>-->
          </van-empty>
        </div>
      </van-tab>
    </van-tabs>


    <van-action-sheet v-model:show="serviceShow" title="课程配套服务" class="service-popup">
      <div class="content2" height="40%">
        <van-cell center :title="item.title" :label="item.desc"  v-for="(item,index) in info.service">
          <template #icon>
            <van-icon :name="item.icon" />
          </template>
        </van-cell>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model:show="teacherShow" title="我的服务老师" class="teacher-popup">
      <div class="content2" height="40%">
        <input type="hidden" id="input">
        <van-cell center :title="service.real_name+'电话'" v-if="service.phone">
          <template #icon>
            <van-icon name="phone-o" />
          </template>
          <template #label>
            <span @click="copy(service.phone)">{{service.phone}}<van-tag style="margin-left:0.6rem">复制</van-tag></span>
          </template>
        </van-cell>
        <van-cell center :title="service.real_name+'微信'" v-if="service.we_chat_code">
          <template #icon>
            <van-icon name="chat-o" />
          </template>
          <template #label>
            <span @click="copy(service.we_chat_code)">{{service.we_chat_code}}<van-tag style="margin-left:0.6rem">复制</van-tag></span>
          </template>
        </van-cell>
        <van-image class="pho-wx" width="20rem" height="20rem" fit="contain"
          :src="service.code" />
      </div>
    </van-action-sheet>

    <van-goods-action>
      <van-goods-action-icon icon="chat-o" text="客服" @click="mySchool" />
<!--      <van-goods-action-icon icon="cart-o" text="购物车" info="9" @click="handelCart" />-->
<!--      <van-goods-action-icon icon="like-o" text="收藏" @click="handelCollectAdd" />-->
     <!-- <van-goods-action-icon icon="wap-home-o" text="首页" @click="handelHome" /> -->
<!--      <van-goods-action-button type="warning" text="加入购物车" @click="handelCartAdd" />-->
      <!-- <van-goods-action-button type="danger" text="立即购买" @click="handelBuy" /> -->
<!--      <van-goods-action-button type="danger" text="咨询服务老师购买课程" @click="handelBuy" />-->
      <!-- <van-goods-action-button type="danger" text="返回首页"  @click="teacherShow = true" /> -->
      <van-goods-action-button type="danger" text="立即购买"  @click="submitOrder" />
    </van-goods-action>


  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from "axios"


import { getCommodityDetail,courseList,informationDetail } from '@/request/api'
import {Toast} from "vant";

export default {
  beforeRouteEnter(to, from, next) {
    let that = this;
    // console.log(to);
    // console.log(from);
    // console.log(next);
    if (!to.params || !to.query.id) {
        next({
            path: from.name,
            query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
    }
    next();
  },
  // metaInfo: {
  //   title: '2022中药全科（精品班）', //设置页面title
  //   meta: [{                 //设置页面meta
  //     name: 'keyWords',
  //     content: '关键词'
  //   },
  //   {
  //     name: "description",
  //     content: "描述",
  //   },
  //   ]
  // },
  metaInfo() {
    return {
      title: this.title, //设置页面title
    }
  },
  data() {
    return {
      title: this.getBranchCompany(),
      //轮播图数组
      banner: [],
      current: 0,
      active: '',
      serviceShow: false,
      teacherShow: false,
      status: '',

      shopList: [],
      commodityId: '',
      info: [],
      courseList: [],
      service: [],
      show_admin: true,
    };
  },
  methods: {
    mySchool(){
      this.$router.push('/MySchool')
    },
    handleClickSearch() {
      this.$router.push('/course/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    onChange(index) {
      this.current = index;
    },
    handelService() {
      this.$router.push('/course/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelCart() {
      Toast('暂未开放');
      // this.$router.push('/cart')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelHome() {
      this.$router.push('/index')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelCollectAdd() {
      let status = true
      const p = document.getElementsByClassName('van-goods-action-icon')
      // const handelCollectAdd = () => {
      if (status) {
        status = false
        // console.log(p[2].childNodes[0])
        p[2].childNodes[0].classList.replace('van-icon-like-o', 'van-icon-like')
        p[2].childNodes[1].data = '已收藏'
        console.log(p[2])
      } else {
        status = true
        p[2].childNodes[0].classList.replace('van-icon-like', 'van-icon-like-o')
        p[2].childNodes[1].data = '收藏'
        console.log("进入收藏")
      }
      // }
    },
    handelCartAdd() {
      // this.$router.push('/courseDetail/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      Toast('功能开发中');
    },
    handelBuy() {
      // this.$router.push('/courseDetail/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      Toast('去咨询服务老师');
    },
    handelCommodityEvaluation() {
      this.$router.push('/commodityEvaluation')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },

    onBack() {
      history.back();
      window.android.back();
    },
    userKefu() {
      this.$router.push({ path: '/user' });
    },
    commodityCourseList() {
      let that = this;
      let commodityId = that.info.id
      courseList({id: commodityId})
              .then(res => {
                if (res.data.code == 1) {
                  that.courseList = res.data.data.data;
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    },
    commodityDetail() {
      let commodityId = this.commodityId;
      let branchId = this.getBranch();
      let that = this;
      getCommodityDetail({id: commodityId})
              .then(res => {
                if (res.data.code == 1) {
                  that.shopList = res.data.data.courseList;
                  that.info = res.data.data.info;
                  that.show_admin = res.data.data.show_admin
                  that.commodityCourseList()
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
      informationDetail({branchId})
              .then(res => {
                if (res.data.code == 1) {
                  that.service = res.data.data.data;
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    },
    handleClickCourseDetail(id) {
      this.$router.push({
        name: 'CourseDetail',
        params: {
          id
        },
        query:{
          id
        }
      })
    },
    copy(title) {
      this.$copyText(title).then(
              function(e) {
                Toast('复制成功')
              },
              function(e) {
                Toast('复制错误')
              }
      );
//       console.log(title)
// //获取input对象
//       var input = document.getElementById("input");
//       console.log(input);
//       input.value = 123123; // 要复制的文本框的内容（此处是后端返回的内容）
//       input.select(); // 选中文本
//       document.execCommand("copy");
//       Toast('复制成功');
    },
    submitOrder(){
      if(this.show_admin === true){
        this.teacherShow =true;
        return false;
      }
      let id =  this.info.id;
      this.$router.push({name: 'Order', query:{id},params: {id},})
    }
  },
  created() {
    this.commodityId = this.$route.query.id;
    this.commodityDetail();
    this.title = this.getBranchCompany();
  },
  components: {

  },

}
</script>
<style lang="less">
@import '~@/assets/styles/common.less';

.course-detail {
  background-color: #ffffff;
  // overflow: hidden;
}
.contentBox img {
  width:100%;
}
.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }
.shoplist1 {
  padding: 0.8rem;
  background-color: #ffffff;
}

.course-title {
  padding: 2.4rem 1.5rem 1.5rem 1.5rem;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: #333;
}

.course-title .van-tag {
  padding: 0.3rem 0.6rem;
  position: relative;
  bottom: 0.2rem;
  margin-right: 0.6rem;
}

.course-title .course-price {
  padding-top: 0.5rem;
  color: #ee0a24;
  font-weight: 600;
  font-size: 2.4rem;
}

.course-title .course-price span {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  color: #333333;
  font-weight: 500;
  font-size: 2rem;
  font-style:italic;
  text-decoration:line-through;
}

.course-service {
  padding: 0 1.5rem;
}

.course-service .van-tag {
  margin-right: 0.4rem;
}

.course-service .weapp-nav-icon {
  font-size: 3rem;
}

.service-popup {
  height: 50% !important;
  ;
}

.service-popup .content2 {
  padding: 1.2rem 0;
}

.service-popup .content2 .van-cell {
  padding: 0 1.5rem;
  margin: 2rem 0;
}

.service-popup .content2 .van-cell::after {
  border-width: 0;
}

.service-popup .content2 .van-cell .van-icon {
  font-size: 4rem;
  margin-right: 0.6rem;
}

.service-popup .content2 .van-cell .van-cell__title {
  font-size: 2rem;
}

.service-popup .content2 .van-cell .van-cell__label {
  font-size: 1.6rem;
}


.teacher-popup {
  height: 50% !important;
  ;
}

.teacher-popup .content2 {
  padding: 1.2rem 0;
}

.teacher-popup .content2 .van-cell {
  padding: 0 1.5rem;
  margin: 2rem 0;
}

.teacher-popup .content2 .van-cell::after {
  border-width: 0;
}

.teacher-popup .content2 .van-cell .van-icon {
  font-size: 4rem;
  margin-right: 0.6rem;
  color: #666666;
}

.teacher-popup .content2 .van-cell .van-cell__title {
  font-size: 2rem;
}

.teacher-popup .content2 .van-cell .van-cell__label {
  font-size: 2rem;
  line-height: 4rem;
}

.teacher-popup .content2 .pho-wx {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.shop_detail {
  background-color: #e7e9ed;
}

.shop_detail .van-tabs__content {
  padding-bottom: 10rem;
}

.shop_detail .courseList {
  margin: 1rem;
  border-radius: 2rem;
  background-color: #ffffff;
}

.shop_detail .courseList .courseListTitle {
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  font-size: 2rem;
  line-height: 2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.shop_detail .courseList .courseListTitle {
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  font-size: 2rem;
  line-height: 2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.shop_detail .courseList .van-cell {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 1rem;
}

.shop_detail .courseList .courseListTitle .van-tag {
  width: auto;
  padding: 0.4rem 1rem;
  line-height: 2rem;
  font-size: 1.8rem;
  justify-content: center;
}

.van-icon-like:before {
  color: #ee0a24 !important;
}

.shop_detail .evaluation {
  margin: 1rem;
  border-radius: 2rem;
  background-color: #ffffff;
}

.shop_detail .evaluation .van-cell {
  border-radius: 1rem;
  padding: 1.5rem 1rem;
}

.shop_detail .evaluation .van-cell .van-icon {
  float: left;
  top: 0;
  margin: 0.3rem 0.6rem;
}

.shop_detail .evaluation .van-cell .van-icon .van-icon__image {
  border-radius: 100%;
}

.shop_detail .evaluation .van-cell .van-cell__title {
  color: #333;
  font-size: 1.8rem;
}

.shop_detail .evaluation .van-cell .evaluationContent {
  color: #333;
  font-size: 1.8rem;
  line-height: 3.2rem;
}

.shop_detail .evaluation .title-1 {
  height: 4rem !important;
}

.shop_detail .evaluation .title-1 .van-cell__title {
  color: #333;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 0 0.5rem;
}

.shop_detail .title-2 {
  height: 4rem !important;
}

.shop_detail .title-2 .van-cell__title {
  color: #333;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 0 1rem !important;
}


.course-detail-title .van-nav-bar__content .van-icon {
  color: #ffffff;
}

.course-detail-title .van-nav-bar__content .van-nav-bar__left .van-icon {
  font-size: 3rem;
  padding: 0.5rem;
  border-radius: 5rem;
  background-color: #666666;
  opacity: 0.6;
  margin-left: 1rem;
  color: #ffffff !important;
}

.course-detail-title .van-nav-bar__content .van-nav-bar__right .van-icon {
  padding: 0.5rem;
  font-size: 3rem;
  border-radius: 5rem;
  background-color: #666666;
  opacity: 0.6;
  margin-right: 1rem;
  color: #ffffff;
}
.course-detail-title::after {
    border-width: 0 !important;
}
</style>
